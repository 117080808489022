<template>
  <div
    no-body
  >
    <div class="greetings">
      <span class="greet-name text-14 text-capitalize font-weight-bolder">Hello  {{ userData.fullName || userData.username }},</span><span class="greet-text text-12"> This is what we've got for you today.</span>
    </div>
    <b-row class="match-height">
      <b-col
        v-for="(item, index) in stat"
        :key="index"
        xl="2"
        md="4"
        sm="6"
      >
        <b-card class="text-center">
          <b-avatar
            :class="item.class"
            :variant="item.variant"
            size="49"
          >
            <feather-icon
              size="20"
              :icon="item.icon"
              :color="item.color"
            />
          </b-avatar>
          <div class="truncate stats">
            <h2 class="mb-25 font-weight-bolder counter">
              {{ item.statistic }}<span class="font-medium-3">{{ item.text }}</span>
            </h2>
            <span class="font-small-3 description">{{ item.statisticTitle }}</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SockJS from 'sockjs-client'
import {
  BRow, BCol, BCard, BAvatar,
} from 'bootstrap-vue'
import axios from '@axios'
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import '@/assets/scss/custom/stats.scss'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  setup() {
    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'
    const stat = ref()

    const getSockMsg = sockMsg => {
      if (sockMsg === true) {
        // Trigger reload or something here
        // console.log(typeof sockMsg, sockMsg)
        // eslint-disable-next-line no-use-before-define
        getTabDATA()
      }
    }

    const getTabDATA = () => {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}DashboardStatistics/getStatistics`, {
            param: moment().format('YYYY-MM-DD'),
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => {
            resolve(response)
            stat.value = response.data.response.data
            // console.log('response', response.data.response.data)
          })
          .catch(error => {
            reject(error)
            // console.log('error', error)
          })
      })
    }

    const initializeSockJs = () => {
      sock.onopen = () => {
      // console.log('Connection established.')
      }

      sock.onmessage = event => {
        const data = JSON.parse(event.data)
        if (data.id === sockId) {
          getSockMsg(data.msg)
        }
      }

      sock.onclose = () => {
      // console.warn('Connection closed.')
        SockJS.call(sock, sock.url)
      }
    }

    getTabDATA()
    initializeSockJs()

    return {
      getTabDATA,
      userData: JSON.parse(localStorage.getItem('userData')),
      stat,
    }
  },
}
</script>
