<!-- eslint-disable -->
<template>
  <b-card
    v-if="recentlyViewed != 0"
    no-body
  >
    <b-card-header>
      <b-card-title>Recent Items</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-list-group
        v-for="(item, index) in recentlyViewed"
        :key="index"
      >
        <b-list-group-item
          class="px-0 border-0"
          @click.prevent="viewRecentPage(item.page_url+item.id)"
        >
          <div class="d-flex flex-row cursor-pointer">
            <div class="px-0">
              <b-avatar
                v-if="item.profilepicture === ''"
                class="text-center"
                size="42"
                variant="primary"
                rounded
                :text="avatarText(`${item.firstname.slice(0,1)} ${item.lastname.slice(0,1)}`)"
              />
              <b-img
                v-if="item.profilepicture !== '' && item.page === 'client'"
                :src="`${urlUpload}/clients/${item.profilepicture}`"
                class="fitPhoto"
                height="42"
                width="42"
                rounded
              />
              <b-img
                v-if="item.profilepicture !== '' && item.page === 'caregiver'"
                :src="`${urlUpload}${item.id}/${item.profilepicture}`"
                class="fitPhoto"
                height="42"
                width="42"
                rounded
              />
            </div>
            <div>
              <div class="text-14 pl-2 black font-weight-bold">
                {{ item.firstname }} {{ item.lastname }}
              </div>
              <div class="gray-1 text-12 pl-2 text-capitalize gray-1">
                {{ item.page }}
              </div>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BListGroup, BListGroupItem, BAvatar, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      recentlyViewed: [],
      urlUpload: this.$urlUpload,
      avatarText,
    }
  },
  created() {
    this.getUserActivity()
  },
  methods: {
    getUserActivity() {
      const formData = {
        id: 1,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'RecentlyView/getRecentlyViewAction', {
        param: formData,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(response => {
          this.recentlyViewed = response.data.recentlyViewedData
        })
    },
    viewRecentPage(param) {
      this.$router.push({ path: param })
    },
  },
}
</script>

<style>
.color-1{
    color:#33475B!important;
}
.color-2 {
  color: #516f90 !important;
}
.text-14 {
  font-size: 14px !important;
}
.text-12 {
  font-size: 12px !important;
}
.fitPhoto {
  object-fit: cover;
}
</style>
