import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchHomecareAlerts(ctx, payload) {
      const accessToken = localStorage.getItem('accessToken')

      return new Promise((resolve, reject) => {
        axios
          // eslint-disable-next-line
          .post(Vue.prototype.$apiUrl2 + 'homecareAlerts/fetchAlerts', payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
