<template>
  <section id="dashboard-section">
    <statistics />
    <b-row>
      <b-col
        lg="8"
        cols="12"
      >
        <homecare-alerts />
      </b-col>
      <b-col
        lg="4"
        cols="12"
      >
        <recently-viewed />
        <!-- <open-shifts /> -->
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import HomecareAlerts from '@/views/apps/homecare-alerts/homecare-alerts-list/HomecareAlertsList.vue'
import Statistics from './Statistics.vue'
// import OpenShifts from './OpenShifts.vue'
import RecentlyViewed from './RecentlyViewed.vue'

export default {
  components: {
    BRow,
    BCol,

    HomecareAlerts,
    Statistics,
    // OpenShifts,
    RecentlyViewed,
  },
  data() {
    return {
      data: [],
      pageTitle: 'Dashboard',
    }
  },
  methods: {
    captureUserActivity() {

    },
  },
}
</script>

<style>
h1{
  margin: auto;
}
</style>
