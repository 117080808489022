/* eslint-disable no-unused-vars */
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import moment from 'moment'

export default function useHomecareAlertsList() {
  // Use toast
  const toast = useToast()

  const refHomecareAlertsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'left', tdClass: 'pr-0 align-baseline extra-whitespace-fix' },
    { key: 'right', tdClass: 'pl-1' },
  ]

  const searchQuery = ref('')
  const activityFilter = ref('Daily')
  const statusFilter = ref(null)
  const sort = ref('datetime')
  const sortDesc = ref(true)
  const page = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const totalCount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refHomecareAlertsListTable.value ? refHomecareAlertsListTable.value.localItems.length : 0
    return {
      from: limit.value * (page.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (page.value - 1) + localItemsCount,
      of: totalCount.value,
    }
  })

  const refetchData = () => {
    refHomecareAlertsListTable.value.refresh()
  }

  watch([searchQuery, activityFilter, statusFilter, sort, sortDesc, page, limit], () => {
    refetchData()
  })

  const fetchHomecareAlerts = (ctx, callback) => {
    store
      .dispatch('app-homecarealerts/fetchHomecareAlerts', {
        q: searchQuery.value,
        activity: activityFilter.value,
        status: statusFilter.value,
        _sort: sort.value,
        _order: (sortDesc.value ? 'desc' : 'asc'),
        _page: page.value,
        _limit: limit.value,
      })
      .then(response => {
        const jsonObjectLiterals = response.data.response.data

        // console.log(jsonObjectLiterals)

        callback(jsonObjectLiterals.data)
        totalCount.value = jsonObjectLiterals.totalCount
      })
      .catch(error => {
        console.error(error)

        /* toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching table list.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        }) */
      })
  }

  const resolveAlertVariant = status => {
    if (status === 'Late Clock-In') return 'danger'
    if (status === 'Late Clock-Out') return 'danger'
    if (status === 'Missed Clock-In') return 'danger'
    if (status === 'Missed Clock-Out') return 'danger'
    // if (status === 'Birthday') return 'completed'
    // if (status === 'Completed Task') return 'completed'
    if (status === 'Incomplete Task') return 'danger'
    // if (status === 'Completed Shift') return 'completed'
    if (status === 'Cancelled Shift') return 'danger'

    if (status === 'Unsigned Care Log') return 'danger'
    if (status === 'Declined Shifts') return 'danger'
    return 'completed'
  }

  const resolveAlertDatetime = datetime => moment(datetime).format('MMM DD, hh:mm a')

  const resolveAlertDetailsTimeSeconds = seconds => {
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    if (seconds < 60) return `${seconds} ${seconds > 1 ? 'seconds' : 'second'}`
    if (minutes < 60) return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`
    if (hours < 60) return `${hours} ${hours > 1 ? 'hours' : 'hour'}`
    return `${seconds} seconds`
  }

  const resolveAlertDetails = (status, data) => {
    if (status === 'Late Clock-In') {
      const seconds = moment(data.clock_in).diff(data.start_shift_plus, 'seconds')
      return `was ${resolveAlertDetailsTimeSeconds(seconds)} late before clocking-in to ${moment(data.start_shift).format('MMM DD, hh:mm a')} shift for`
    }
    if (status === 'Late Clock-Out') {
      const seconds = moment(data.clock_out).diff(data.end_shift_plus, 'seconds')
      return `was ${resolveAlertDetailsTimeSeconds(seconds)} late before clocking-out to ${moment(data.end_shift).format('MMM DD, hh:mm a')} shift for`
    }
    if (status === 'Missed Clock-In') {
      return `missed a clock-in on ${moment(data.start_shift).format('MMM DD, hh:mm a')} shift for`
    }
    if (status === 'Missed Clock-Out') {
      return `missed a clock-out on ${moment(data.end_shift).format('MMM DD, hh:mm a')} shift for`
    }
    if (status === 'Birthday') return 'has a birthday'
    if (status === 'Completed Task') {
      return `completed the "${data.task}" task for`
    }
    if (status === 'Incomplete Task') {
      return `did not completed the "${data.task}" task for`
    }
    if (status === 'Completed Shift') {
      return `completed the ${moment(data.start_shift).format('MMM DD, hh:mm a')} shift for`
    }
    if (status === 'Cancelled Shift') {
      if (data.id === 0 || data.id === '0') return `cancelled the ${moment(data.start_shift).format('MMM DD, hh:mm a')} Open Shift`
      return `cancelled the ${moment(data.start_shift).format('MMM DD, hh:mm a')} shift for`
    }
    return ''
  }

  const resolveAlertPath = type => {
    if (type === 'caregiver') return 'caregiver-view'
    if (type === 'client') return 'client-profile'
    return false
  }

  return {
    refHomecareAlertsListTable,
    tableColumns,
    searchQuery,
    activityFilter,
    statusFilter,
    sort,
    sortDesc,
    page,
    limit,
    limitOptions,
    totalCount,
    dataMeta,

    refetchData,

    fetchHomecareAlerts,

    resolveAlertVariant,
    resolveAlertDatetime,
    resolveAlertDetails,
    resolveAlertPath,
  }
}
