<template>

  <!-- Card -->
  <b-card>

    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >

        <b-card-title class="mb-0 mb-md-2 black">
          HomeCare Alerts
        </b-card-title>

        <!-- Input Entries -->
        <label class="mr-1 d-none">Entries</label>
        <v-select
          v-model="limit"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="limitOptions"
          taggable
          push-tags
          :clearable="false"
          class="homecare-alerts-limit-select d-none"
        />

      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-end"
      >

        <!-- Input Search -->
        <b-form-input
          v-model="searchQuery"
          type="search"
          placeholder="Search"
          class="d-none mr-1"
        />

        <!-- Select Activity -->
        <v-select
          v-model="activityFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="activityOptions"
          class="homecare-alerts-activity-filter-select mr-1"
          :clearable="false"
          placeholder="Select Activity"
        >

          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>

        </v-select>

        <!-- </b-col>
      <b-col
        cols="12"
        md="3"
        class="d-flex align-items-center justify-content-end pl-1 pl-md-0"
      > -->

        <!-- Select Status -->
        <v-select
          v-model="statusFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusOptions"
          class="homecare-alerts-status-filter-select"
          placeholder="Select Status"
        >

          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>

        </v-select>

      </b-col>
    </b-row>

    <!-- Table -->
    <b-table
      ref="refHomecareAlertsListTable"
      :fields="tableColumns"
      :items="fetchHomecareAlerts"
      thead-class="d-none"
      primary-key="id"
      :sort-by.sync="sort"
      :sort-desc.sync="sortDesc"
      show-empty
      empty-text="No matching records found"
      responsive
      class="homecare-alerts-list-table position-relative mt-1 mb-2 text-12"
    >

      <!-- Column Left -->
      <template #cell(left)="data">
        <span
          class="text-nowrap"
          :class="`text-${resolveAlertVariant(data.item.status)}`"
        >
          {{ resolveAlertDatetime(data.item.timestamp) }}
        </span>
      </template>

      <!-- Column Right -->
      <template #cell(right)="data">
        <b-link
          v-if="data.item.type === 'caregiver'"
          class="text-capitalize"
          :to="{ name: resolveAlertPath(data.item.type), params: { caregiverId: data.item.person_id } }"
        >
          {{ data.item.name }}
        </b-link>
        <b-link
          v-if="data.item.type === 'client'"
          class="text-capitalize"
          :to="{ name: resolveAlertPath(data.item.type), params: { action: 'edit' }, query: { id: data.item.person_id } }"
        >
          {{ data.item.name }}
        </b-link>
        <span
          v-if="data.item.type === 'admin'"
          class="text-danger text-capitalize"
        >
          {{ data.item.name }} (HR/Admin)
        </span>
        {{ resolveAlertDetails(data.item.status, data.item.data) }}
        <b-link
          v-if="data.item.data.type === 'caregiver'"
          class="text-capitalize"
          :to="{ name: resolveAlertPath(data.item.data.type), params: { caregiverId: data.item.data.id } }"
        >
          {{ data.item.status === 'Cancelled Shift' && data.item.data.id != 0 ? data.item.data.name : '' }}
        </b-link>
        <b-link
          v-if="data.item.data.type === 'client'"
          class="text-capitalize"
          :to="{ name: resolveAlertPath(data.item.data.type), params: { action: 'edit' }, query: { id: data.item.data.id } }"
        >
          {{ data.item.data.name }}
        </b-link>
      </template>
    </b-table>

    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-sm-0"
      >

        <!-- Display Entries -->
        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>

      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >

        <!-- Pagination -->
        <b-pagination
          v-model="page"
          :total-rows="totalCount"
          :per-page="limit"
          first-number
          last-number
          class="mb-0"
          prev-class="prev-item"
          next-class="next-item"
        >

          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>

          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>

        </b-pagination>

      </b-col>
    </b-row>

    <button
      class="mt-1 d-none"
      @click="sendSockMsg(true)"
    >
      Test
    </button>

  </b-card>

</template>

<script>
import SockJS from 'sockjs-client'
import {
  BCard, BCardTitle, BRow, BCol, BFormInput, BTable, BPagination, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useHomecareAlertsList from './useHomecareAlertsList'

import homecareAlertsStoreModule from '../homecareAlertsStoreModule'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BLink,

    vSelect,
  },
  setup() {
    const HOMECARE_ALERTS_APP_STORE_MODULE_NAME = 'app-homecarealerts'

    // Register module
    if (!store.hasModule(HOMECARE_ALERTS_APP_STORE_MODULE_NAME)) store.registerModule(HOMECARE_ALERTS_APP_STORE_MODULE_NAME, homecareAlertsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOMECARE_ALERTS_APP_STORE_MODULE_NAME)) store.unregisterModule(HOMECARE_ALERTS_APP_STORE_MODULE_NAME)
    })

    const activityOptions = [
      'Daily',
      'Weekly',
      'Monthly',
    ]

    const statusOptions = [
      'Late Clock-In',
      'Late Clock-Out',
      'Missed Clock-In',
      'Missed Clock-Out',
      'Birthday',
      'Completed Task',
      'Incomplete Task',
      'Completed Shift',
      'Cancelled Shift',
      // 'Unverified Clock-In',
      // 'General Comment',
      // 'Family Room Picture',
      // 'Unsigned Care Log',
      // 'Declined Shift',
      // 'Missed Task',
      // 'Unassigned Task',
    ]

    const {
      refHomecareAlertsListTable,
      tableColumns,
      searchQuery,
      activityFilter,
      statusFilter,
      sort,
      sortDesc,
      page,
      limit,
      limitOptions,
      totalCount,
      dataMeta,

      refetchData,

      fetchHomecareAlerts,

      resolveAlertVariant,
      resolveAlertDatetime,
      resolveAlertDetails,
      resolveAlertPath,
    } = useHomecareAlertsList()

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-dw01'

    return {
      refHomecareAlertsListTable,
      tableColumns,
      searchQuery,
      activityFilter,
      statusFilter,
      sort,
      sortDesc,
      page,
      limit,
      limitOptions,
      totalCount,
      dataMeta,

      refetchData,

      fetchHomecareAlerts,

      resolveAlertVariant,
      resolveAlertDatetime,
      resolveAlertDetails,
      resolveAlertPath,

      activityOptions,
      statusOptions,

      sock,
      sockId,
    }
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }

    setInterval(() => {
      const today = new Date()

      if (today.getMinutes() % 15 === 0 && today.getSeconds() === 59) {
        // console.log(today.getHours(), today.getMinutes(), today.getSeconds())
        this.refetchData()
      }
    }, 1500)
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        this.refetchData()
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.extra-whitespace-fix {
  width: 1% !important;
}
</style>

<style lang="scss" scoped>
.homecare-alerts-limit-select {
  width: 90px;
}

.homecare-alerts-activity-filter-select {
  width: 100%;
  /* min-width: 190px; */

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

/* @media (max-width: 575.98px) {
  .homecare-alerts-activity-filter-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 100%;
    }
  }
} */

.homecare-alerts-status-filter-select {
  /* width: 100%; */
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

/* @media (max-width: 575.98px) {
  .homecare-alerts-status-filter-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 100%;
    }
  }
} */

.homecare-alerts-list-table {
  ::v-deep .table > tbody > tr:first-child > td {
    border: none;
  }
}
</style>
